.Footer {
    position: relative;
    height: 100vh;
    width: 100%;
    background: #3F3D56;
}

.FooterList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.FooterListTitle {
    color: #FF889E;
}

.FooterListItem {
    color: #FF889E;
    padding: 16px 0;
    text-decoration: none;
}

.FooterLink {
    color: #FF889E;
    text-decoration: none;
}