#splash-container {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: rgba(255,219,219,1);
    z-index: 9999;
    animation: fade 1s ease, hide 1s ease 3.5s forwards;
}

/* #stigma, #petal_1, #petal_3, #petal_4, #petal_5 {
    transform-origin: bottom;
    transform-box: fill-box;
} */
#stigma {
    animation: fade 0.5s ease forwards;
}
#petal_1 {
    opacity: 0;
    animation: fade 0.5s ease 0.5s forwards;
}
#petal_2 {
    opacity: 0;
    transform-origin: left;
    transform-box: fill-box;
    transform: rotate(-90deg);
    animation: fade 1s ease 1.5s forwards, petalAni 1s linear 1s forwards;
}
#petal_3 {
    opacity: 0;
    transform-box: fill-box;
    transform: rotate(-145deg);
    animation: fade 1s ease 2s forwards, petalAni 1s linear 1.5s forwards;
}
#petal_4 {
    opacity: 0;
    transform-origin: top;
    transform-box: fill-box;
    transform: rotate(-160deg);
    animation: fade 1s ease 2.5s forwards, petalAni 1s linear 2s forwards;
}
#petal_5 {
    opacity: 0;
    transform-origin: right;
    transform-box: fill-box;
    transform: rotate(-260deg);
    animation: fade 1s ease 3s forwards, petalAni 1s linear 2.5s forwards;
}

@keyframes fade {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes petalAni {
    to { transform: rotate(0deg) };
}

@keyframes hide {
    from { opacity: 1; }
    to { opacity: 0; display: none; }
}