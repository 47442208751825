.Hero {
    position: relative;
    height: 100vh;
    width: 100%;
}

.Overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.15);
}

.Text {
    position: absolute;
    bottom: 32px;
    right: 32px;
    width: 340px;

    & > * {
        margin: 0;
        color: #FFDBDB;
        text-align: left;
    }
}