.Divider {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    height: 56px;
    width: 100%;
    background: #3F3D56;
    z-index: 999;
}

.DividerIcon {
    position: absolute;
    bottom: -28px;
    z-index: inherit;
}