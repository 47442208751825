#leftFlower, #leftFlowerStigma {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: leftFlowerAni 1s linear 2s forwards;
}
#leftStem {
    transform: scale(0);
    transform-origin: bottom;
    transform-box: fill-box;
    animation: leftFlowerStemAni 2s linear forwards;
}
#leftLeaf {
    transform: scale(0);
    transform-origin: right;
    transform-box: fill-box;
    animation: leftFlowerStemAni 3s linear 2s forwards;
}
#leftBranch {
    transform: scale(0);
    transform-origin: right;
    transform-box: fill-box;
    animation: leftFlowerStemAni 2s linear 1s forwards;
}
#leftPod {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: leftFlowerStemAni 1s linear 3s forwards;
}

@keyframes leftFlowerAni {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes leftFlowerStemAni {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

#centerFlower, #centerFlowerStigma {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: centerFlowerAni 1s linear 2s forwards;
}
#centerStem {
    transform: scale(0);
    transform-origin: bottom;
    transform-box: fill-box;
    animation: centerFlowerStemAni 2s linear forwards;
}
#centerLeaf {
    transform: scale(0);
    transform-origin: right;
    transform-box: fill-box;
    animation: centerFlowerStemAni 3s linear 2s forwards;
}
#centerBranch {
    transform: scale(0);
    transform-origin: right;
    transform-box: fill-box;
    animation: centerFlowerStemAni 2s linear 1s forwards;
}
#centerPod {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: centerFlowerStemAni 1s linear 3s forwards;
}
#centerBranch2 {
    transform: scale(0);
    transform-origin: left;
    transform-box: fill-box;
    animation: centerFlowerStemAni 2s linear 1s forwards;
}

@keyframes centerFlowerAni {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes centerFlowerStemAni {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

#rightFlower, #rightFlowerStigma {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: rightFlowerAni 1s linear 2s forwards;
}
#rightStem {
    transform: scale(0);
    transform-origin: bottom;
    transform-box: fill-box;
    animation: rightFlowerStemAni 2s linear forwards;
}
#rightLeaf {
    transform: scale(0);
    transform-origin: right;
    transform-box: fill-box;
    animation: rightFlowerStemAni 3s linear 2s forwards;
}
#rightBranch {
    transform: scale(0);
    transform-origin: right;
    transform-box: fill-box;
    animation: rightFlowerStemAni 2s linear 1s forwards;
}
#rightPod {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: rightFlowerStemAni 1s linear 3s forwards;
}

@keyframes rightFlowerAni {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes rightFlowerStemAni {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

#dot1 {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: dotAni 1.5s linear forwards;
}
#dot2 {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: dotAni 1.5s linear forwards;
}
#dot3 {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: dotAni 1.5s linear forwards;
}
#dot4 {
    transform: scale(0);
    transform-origin: center;
    transform-box: fill-box;
    animation: dotAni 1.5s linear forwards;
}

@keyframes dotAni {
    to {
        transform: scale(1)
    }
}